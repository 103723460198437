<template>
<div class="dashboard-page">

  <!-- table: KPI -->
  <div class="row">
    <div class="col-sm-12">
      <vuestic-widget :loading="loading" headerText="KPI per Business">
        <datatable v-bind="data" class="le-datatable">
        </datatable>
      </vuestic-widget>
    </div>
  </div>
</div>

</template>

<script>
import components from '../../common/tables/comps'
import orderBy from 'lodash/orderBy'
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export default {
  components: {
  },
  props: {
    filter: {
      type: Object,
      default: {},
    }
  },
  data: function () {
    return {
      loading: false,
      dataSet: [],
      data: {
        supportBackup: false,
        supportNested: true,
        HeaderSettings: false,
        tblClass: 'table-bordered',
        tblStyle: 'color: #666',
        pageSizeOptions: [5, 10, 25, 50, 100],
        columns: (() => {
          const cols = [
          { title: 'Name', sortable: true, field: 'name', tdStyle: { fontStyle: 'normal' }, tdClass: 'center' },
          { title: 'Texts Sent', sortable: true, field: 'sms_sent', tdStyle: { fontStyle: 'normal' }, tdClass: 'center' },
          { title: 'Texts Received', sortable: true, field: 'sms_received', tdStyle: { fontStyle: 'normal' }, tdClass: 'center' },
          { title: 'Texts Failed', sortable: true, field: 'sms_failed', tdStyle: { fontStyle: 'normal' }, tdClass: 'center' },
          { title: 'Total Calls', sortable: true, field: 'call_total', tdStyle: { fontStyle: 'normal' }, tdClass: 'center' },
          { title: 'Connected Calls', sortable: true, field: 'call_connected', tdStyle: { fontStyle: 'normal' }, tdClass: 'center' },
          ];
          return cols;
        })(),
        data: [],
        total: 0,
        summary: {},
        query: {
          search: null
        },
        search: null,
        xprops: {
          eventbus: new Vue(),
        },
      },
    }
  },
  mounted() {
    this.handleFilterUpdate();
  },

  watch: {
    'data.query': {
      handler () {
        this.handleQueryChange()
      },
      deep: true
    }
  },  
  
  methods: {
    handleQueryChange() {
      let {offset, limit, search, sort, order } = this.data.query
      if (sort)
      {
        this.dataSet = orderBy(this.dataSet, sort, order)
      }
      this.data.data = this.dataSet.slice(offset, offset + limit);
    },

    handleFilterUpdate() {
      const filter = {
        business: this.filter.business.value.id,
        rangeDateStart: moment(this.filter.date.dateRange.startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
        rangeDateEnd: moment(this.filter.date.dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
      };
      this.loading = true
      const param = {
        type: 'kpi',
        filter
      }
      this.$store.dispatch('agency/fetchAnalytic', param)
        .then((data) => {
          this.loading = false
          if(!data.success) return;
          this.dataSet = data.data;
          this.data.total = this.dataSet.length
          this.handleQueryChange();
        }).catch(e => {
          this.loading = false
        })
    },
  },
};
</script>

<style lang="scss" scoped>
  
</style>
